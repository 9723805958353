import { Link } from "gatsby";
import * as React from "react";
import Container from "../components/Container";
import Prose from "../components/Prose";
import image from "../images/IMG_20220614_052655C.jpg";

function IndexPage() {
  return (
    <Container>
      <Prose>
        <h1>Hanifan Azka's Website</h1>
        <p>Hello</p>
        <Link to="/qurbanDH">
          <img className="w-full" src={image} />
        </Link>
      </Prose>
    </Container>
  );
}

export default IndexPage;
